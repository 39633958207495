import React, { useState, useEffect } from 'react';
import TableWrapper from '../../components/table/table_wrapper';
import { NavLink } from 'react-router-dom';
import mealService from '../../api/meal';
import DishService from '../../api/dish_service';


export default function Meal() {
    const [meal_types, setMeal] = useState([]);
    const [names, setName] = useState([]);

    const getMealData = async () => {
        try {
            const getData = await mealService.getMeals();
            const getDishes = await DishService.getDishes();
            var nameHolder = [];
            


            console.log(getData)
            if (getData && getDishes) {

                //Formulating the name of the dishes.
                getData.map(item => {
                    var tempName = "";
                    item.courses[0].dishes.map(data => {                            
                        getDishes.map(dish => {
                            if (dish._id == data.dish_id) {
                                tempName += (dish.names[0].name) + ", ";
                            }
                        })
                    })
                    nameHolder = [...nameHolder, {_id: item._id, name: tempName}]
                })

                console.log(nameHolder);
                setMeal(nameHolder)
            }
        }
        catch (error) {
            console.log(error)
        }
    };

    useEffect(() => { getMealData() }, []);

    return (
        <div>
            <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                <NavLink to="/create-meal" className="btn btn-dark">
                    Add Meal
                </NavLink>
            </div>
            {meal_types.length > 0 && < TableWrapper data={meal_types} link="/view-meal" />}
        </div>
    );
}