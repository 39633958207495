
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './components/navigation/header'
import Layout from "./components/layout/layout";
import Index from "./pages";
import Login from './pages/login'
import Items from './pages/items/index';
import Nutrients from "./pages/nutrients";
import CreateItem from "./pages/items/create"
import ViewItem from "./pages/items/view";
import CreateNutrient from "./pages/nutrients/create";
import ViewNutrient from "./pages/nutrients/view";
import ChefActions from "./pages/chef_actions";
import CreateChefAction from "./pages/chef_actions/create";
import ViewChefAction from "./pages/chef_actions/view";
import RecipceUnitScheme from "./pages/recipe_unit_scheme";
import CreateRecipeUnitScheme from "./pages/recipe_unit_scheme/create";
import ViewRecipeUnitScheme from "./pages/recipe_unit_scheme/view";
import Dishes from "./pages/dishes";
import CreateDish from "./pages/dishes/create";
import ViewDish from "./pages/dishes/view";
import FactorCategory from "./pages/factor_categories";
import CreateFactorCategory from "./pages/factor_categories/create";
import ViewFactorCategory from "./pages/factor_categories/view";
import ViewFactor from "./pages/factors/view";
import MealCourse from "./pages/meal_course";
import CreateMealCourse from "./pages/meal_course/create";
import ViewMealCourse from "./pages/meal_course/view";
import MealType from "./pages/meal_type";
import CreateMealType from "./pages/meal_type/create";
import ViewMealType from "./pages/meal_type/view";
import Meal from "./pages/meal";
import CreateMeal from "./pages/meal/create";
import ViewMeal from "./pages/meal/view";



function App() {
  const TOKEN = window.localStorage.getItem('token');
  //console.log("token: " + TOKEN)
  return (
    <div className="container-fluid">
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="/" element={TOKEN ? <Layout /> : <Navigate to='/login' />}>
            <Route index element={TOKEN ? <Index /> : <Navigate to='/login' />} />
            <Route path="items" element={TOKEN ? <Items /> : <Navigate to='/login' />} />
            <Route path="create-item" element={TOKEN ? <CreateItem /> : <Navigate to='/login' />} />
            <Route path="view-item/:id" element={TOKEN ? <ViewItem /> : <Navigate to='/login' />} />
            <Route path="nutrients" element={TOKEN ? <Nutrients /> : <Navigate to='/login' />} />
            <Route path="create-nutrient" element={TOKEN ? <CreateNutrient /> : <Navigate to='/login' />} />
            <Route path="view-nutrient/:id" element={TOKEN ? <ViewNutrient /> : <Navigate to='/login' />} />
            <Route path="chef-actions" element={TOKEN ? <ChefActions /> : <Navigate to='/login' />} />
            <Route path="create-chef-action" element={TOKEN ? <CreateChefAction /> : <Navigate to='/login' />} />
            <Route path="view-chef-action/:id" element={TOKEN ? <ViewChefAction /> : <Navigate to='/login' />} />
            <Route path="recipe-unit-scheme" element={TOKEN ? <RecipceUnitScheme /> : <Navigate to='/login' />} />
            <Route path="create-recipe-unit-scheme" element={TOKEN ? <CreateRecipeUnitScheme /> : <Navigate to='/login' />} />
            <Route path="view-recipe-unit-scheme/:id" element={TOKEN ? <ViewRecipeUnitScheme /> : <Navigate to='/login' />} />
            <Route path="dishes" element={TOKEN ? <Dishes /> : <Navigate to='/login' />} />
            <Route path="create-dish" element={TOKEN ? <CreateDish /> : <Navigate to='/login' />} />
            <Route path="view-dish/:id" element={TOKEN ? <ViewDish /> : <Navigate to='/login' />} />

            /********************************/
            <Route path="factor-categories" element={TOKEN ? <FactorCategory /> : <Navigate to='/login' />} />
            <Route path="create-factor-category" element={TOKEN ? <CreateFactorCategory /> : <Navigate to='/login' />} />
            <Route path="view-factor-category/:id" element={TOKEN ? <ViewFactorCategory /> : <Navigate to='/login' />} />
            <Route path="view-factor/:id" element={TOKEN ? <ViewFactor /> : <Navigate to='/login' />} />
            <Route path="meal-courses" element={TOKEN ? <MealCourse /> : <Navigate to='/login' />} />
            <Route path="create-meal-course" element={TOKEN ? <CreateMealCourse /> : <Navigate to='/login' />} />
            <Route path="view-meal-course/:id" element={TOKEN ? <ViewMealCourse /> : <Navigate to='/login' />} />
            <Route path="meal-types" element={TOKEN ? <MealType /> : <Navigate to='/login' />} />
            <Route path="create-meal-type" element={TOKEN ? <CreateMealType /> : <Navigate to='/login' />} />
            <Route path="view-meal-type/:id" element={TOKEN ? <ViewMealType /> : <Navigate to='/login' />} />
            <Route path="meals" element={TOKEN ? <Meal /> : <Navigate to='/login' />} />
            <Route path="create-meal" element={TOKEN ? <CreateMeal /> : <Navigate to='/login' />} />
            <Route path="view-meal/:id" element={TOKEN ? <ViewMeal /> : <Navigate to='/login' />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;