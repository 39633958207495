import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import DishService from '../../../api/dish_service';
import foodItemsService from '../../../api/food_items_service';
import recipeUnitSchemeService from '../../../api/recipe_unit_scheme_service';
import DynamicFields from '../../FormElements/DynamicFields';
import DynamicPills from '../../DynamicPills/DynamicPills';
import CreateDynamicFields from '../../FormElements/CreateDynamicFields';

export default function CreateDishForm({ purpose,
    ...props
}) {

    const [showToast, setShowToast] = useState(false);
    const [disabled, setDisabled] = useState(false);
    /*Form Values */
    const language = "659b6f8f48e3e48cea5bbccf";
    const [name, setName] = useState("");
    const [nameValidator, setNameValidator] = useState(false);
    const [servings, setServings] = useState("");
    const [description, setDescription] = useState("");
    const [instructions, setInstructions] = useState([]);
    const [ingredients, setAllIngredients] = useState([]);
    const [ingredientContainer, setIngredientsContainer] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    //const [selectedIngredients, setSelectedIngredients] = useState([]);
    const sets = ingredients;

    useEffect(() => {
        setShowToast(false);
    }, []);

    const getFoodItemsData = async () => {
        try {
            const getData = await foodItemsService.getFoodItems();
            const getUnitofMeasurement = await recipeUnitSchemeService.getRecipeUnitSchemes();

            if (getData.length > 0) {
                const getIngredients = getData.map(data => {
                    data.name = data.names[0].name;
                    if (data.measuring_unit) {
                        getUnitofMeasurement.forEach(obj => {
                            if (obj._id === data.measuring_unit) {
                                //data.measuring_unit = obj.names[0].name;
                                const item = { ...data, measuring_unit: obj.names[0].name };
                                ////console.log(item);
                            }
                        })
                    }
                    return data;
                })

                //console.log(getIngredients)

                setAllIngredients(getIngredients);
                //const item = unitMeasurements.find(obj => obj.id === data._id);
            }
            if (getData) { setIngredientsContainer(getData) }
            //if (getUnitofMeasurement) { setUnitMeasurements(getUnitofMeasurement) }
        }
        catch (error) {
            //console.log(error)
        }
    };

    const handleCreate = async (event) => {
        event.preventDefault();
        const names = { 'language_id': language, 'name': name }
        /*         //console.log(names)
                //console.log(description)
                //console.log(instructions)
                //console.log(servings)
                ////console.log(ingr)
        
                /*  //console.log("name" + names)
                 //console.log("description" + description)
                 //console.log("language_id" + names.language_id) *
                //console.log(JSON.stringify(selectedOptions)) */

        setDisabled(true);

        try {
            const createData = await DishService.createDish(names, description, instructions, servings, selectedOptions);
            //console.log(createData)
            if (createData) {
                setShowToast(true);
                setDisabled(false);
            }
        }
        catch (error) { setDisabled(false); }
    };

    const handleIngredientChange = (id, index, event) => {
        const newInputValues = selectedOptions.map(data =>
            data.item_id === id ? { ...data, [index]: event.target.value } : data)
        setSelectedOptions(newInputValues);
    }

    const handleIngredientChecked = (id, index, event) => {
        const newInputValues = selectedOptions.map(data =>
            data.item_id === id ? { ...data, [index]: event.target.checked } : data)
        setSelectedOptions(newInputValues);
    }

    const handleRecipeChange = (values) => {
        setInstructions(values);
    };

    /*************Handling and creating Table fields of selected ingredients */
    const handleSelectIngredient = (event) => {
        var ingredientName = event.target.name;
        var measuringUnit = event.target.measuring_unit;
        var id = event.target.id;
        var itsPresent = false;
        var ingredient = { item_id: id, quantity: 0, name: ingredientName, measuring_unit: measuringUnit, main_ingredient: false, reduceable: false, removeable: false, substitutes: [] }
        ////console.log(ingredient)
        let selectedLabels = [...selectedOptions];
        if (selectedLabels.length > 0) {
            itsPresent = selectedLabels.some(item => item.name.includes(ingredient?.name))
        }
        if (itsPresent) {
            selectedLabels = selectedLabels.filter(item => item.name !== ingredient.name);
            setSelectedOptions(selectedLabels);
        }
        else {
            selectedLabels = [...selectedOptions, ingredient]
            setSelectedOptions(selectedLabels);
        }

        console.log(selectedOptions)
    };

    /*******Search algorithm */
    const search = event => {
        event.preventDefault();
        const input = event?.target?.value?.toLowerCase();
        let filteredList = sets;

        filteredList = sets?.filter(item => {
            let result = false;
            if (item?.name?.toLowerCase()?.includes(input) && result === false) {
                result = true;
            }
            return result;
        });

        setIngredientsContainer(filteredList);
    };

    const list = ingredientContainer?.map(set => {
        return (
            <div className='mb-3 col-6' key={set._id}>
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" id={set._id} name={set.name} measuring_unit={set.measuring_unit} onClick={handleSelectIngredient} />
                    <label className="form-check-label w-100" htmlFor={set.name} >
                        {set.name}
                    </label>
                </div>
            </div>)
    })

    const ingredientList = selectedOptions.map(set => {
        return (
            <tr key={set.item_id} className="g-3">
                <th className='col-2' scope="row">{set.name}</th>
                <td className='col-5'> <div className="form-floating">
                    <input type="text" className="form-control" id={set.item_id} onChange={(e) => handleIngredientChange(set.item_id, 'quantity', e)} />
                    <label for={set.item_id}>{set.measuring_unit}</label>
                </div></td>
                <td className='col-1'><input className="form-check-input" type="checkbox" checked={set.main_ingredient || ''} id="flexCheckDefault" onChange={(e) => handleIngredientChecked(set.item_id, 'main_ingredient', e)} /></td>
                <td className='col-1'><input className="form-check-input" type="checkbox" checked={set.reduceable || ''} id="flexCheckDefault" onChange={(e) => handleIngredientChecked(set.item_id, 'reduceable', e)} /></td>
                <td className='col-1'><input className="form-check-input" type="checkbox" checked={set.removeable || ''} id="flexCheckDefault" onChange={(e) => handleIngredientChecked(set.item_id, 'removeable', e)} /></td>
            </tr>
        );
    });


    useEffect(() => { getFoodItemsData() }, []);
    //console.log(selectedIngredients);

    return (
        <div>
            <div className='d-flex justify-content-end'>
                <Toast className="bg-success-subtle text-center" show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                    <Toast.Header className='d-flex justify-content-end'>
                    </Toast.Header>
                    <Toast.Body>Dish Created</Toast.Body>
                </Toast>
            </div>

            <form className='bg-light p-3'>
                <h1 className="h3 mb-3 fw-normal py-4 text-center">Create Dish</h1>
                <div className="form-floating required py-4">
                    <input type="text" className={nameValidator ? `form-control is-invalid` : "form-control"} id="name" onChange={(e) => setName(e.target.value)} />
                    <label className="control-label" htmlFor="floatingInput">Dish Name<small className='text-danger'>*</small></label>
                    {nameValidator && (<p className='text-danger'>Name is required.</p>)}
                </div>
                <div className="form-floating py-4">
                    <textarea type="text" className="form-control" id="description" onChange={(e) => setDescription(e.target.value)} />
                    <label className='control-label' htmlFor="floatingInput">Dish Description</label>
                </div>

                <div className="border bg-white p-3">
                    <h5 className='p-2 text-center'>Ingredients and Measurement</h5>
                    <div className="dropdown d-flex justify-content-end mr-4">
                        <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                            Select Ingredients
                        </button>
                        <div className="dropdown-menu p-4 w-50">
                            <form className="w-100 fluid" role="search">
                                <input className="form-control" type="search" placeholder="Search" aria-label="Search" onChange={search} />
                            </form>
                            <div className="row">
                                {list}
                            </div>
                        </div>
                    </div>

                    <div className="m-4 g-3  p-4">
                        <table className="table table-striped ">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Measurement</th>
                                    <th scope="col">Main</th>
                                    <th scope="col">Reduce</th>
                                    <th scope="col">Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ingredientList}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="form-floating py-4">
                    <CreateDynamicFields onFieldsChange={handleRecipeChange} />
                    <label className='control-label' htmlFor="floatingInput">Instructions</label>
                </div>

                {/*                 <DynamicFields onFieldsChange={handleRecipeChange} /> */}

                <div className="form-floating py-4">
                    <textarea type="text" className="form-control" id="servings" onChange={(e) => setServings(e.target.value)} />
                    <label className='control-label pb-2' htmlFor="floatingInput">Servings</label>
                </div>

                <div className="container mt-5">
                    <h1 class="text-center">Manage Ingredient Substitutes</h1>
                    <DynamicPills items={selectedOptions} possibleSubstitutes={ingredients} selectedIngredients={selectedOptions} setSelectedIngredients={setSelectedOptions} />
                </div>

                <div className='row justify-content-center'>
                    <button className="btn btn-dark py-4 my-4 col-6" onClick={handleCreate} disabled={disabled}>{purpose}</button>
                </div>
            </form>
        </div>
    )
}