import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import mealTypeService from '../../../api/meal_type';

export default function MealTypeForm({ purpose, id,
    ...props
}) {
    const [showToast, setShowToast] = useState(false);
    const [disabled, setDisabled] = useState(false);
    /*Form Values */
    const [language, setLanguage] = useState("");
    const [name, setName] = useState("");
    const [nameValidator, setNameValidator] = useState(false);
    const [description, setDescription] = useState("");
    const [startHour, setStartHour] = useState('');
    const [startMinute, setStartMinute] = useState('');
    const [endHour, setEndHour] = useState('');
    const [endMinute, setEndMinute] = useState('');

    // Array of numbers for dropdowns
    const hours = Array.from({ length: 24 }, (_, i) => i);
    const minutes = [0, 15, 30, 45];

    useEffect(() => {
        setShowToast(false);
    }, []);

    const getMealType = async () => {
        try {
            const getData = await mealTypeService.getMealType(id);
            if (getData) {
                console.log(getData)
                setName(getData.names[0].name);
                setLanguage(getData.names[0].language_id);
                setDescription(getData.description);
                setStartHour(getData.start_time.hour);
                setStartMinute(getData.start_time.minute);
                setEndHour(getData.end_time.hour);
                setEndMinute(getData.end_time.minute);
            }
        }
        catch (error) {
            console.log(error)
        }
    };


    const handleUpdate = async (event) => {
        event.preventDefault();
        const names = { 'language_id': language, 'name': name }
        const start_time = { hour: parseInt(startHour), minute: parseInt(startMinute) }
        const end_time = { hour: parseInt(endHour), minute: parseInt(endMinute) }

        /* if (validate() === true) {
            console.log("Failed" + nameValidator + macroValidator)
            return
        }*/
        setDisabled(true);

        try {
            const createData = await mealTypeService.updateMealType(names, description, start_time, end_time, id);
            if (createData) {
                setShowToast(true);
                setDisabled(false);
            }
        }
        catch (error) { setDisabled(false); }
    };

    useEffect(() => { getMealType() }, []);

    console.log("start", startMinute)
    console.log("end", endMinute)
    return (
        <div>
            <div className='d-flex justify-content-end'>
                <Toast className="bg-success-subtle text-center" show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                    <Toast.Header className='d-flex justify-content-end'>
                    </Toast.Header>
                    <Toast.Body>Meal Type Updated</Toast.Body>
                </Toast>
            </div>
            <form className='bg-light p-3'>
                <h1 className="h3 mb-3 fw-normal py-4 text-center">Update Meal Type</h1>
                <div className="form-floating required py-4">
                    <input type="text" className={nameValidator ? `form-control is-invalid` : "form-control"} value={name} id="name" onChange={(e) => setName(e.target.value)} />
                    <label className="control-label" htmlFor="floatingInput">Meal Type Name<small className='text-danger'>*</small></label>
                    {nameValidator && (<p className='text-danger'>Name is required.</p>)}
                </div>
                <div className="form-floating py-4">
                    <textarea type="text" className="form-control" value={name} id="description" onChange={(e) => setDescription(e.target.value)} />
                    <label className='control-label' htmlFor="floatingInput">Meal Type Description</label>
                </div>


                <div className='row justify-content-between'>
                    <div className="row col-md-4 col-12">
                        <label className="control-label pt-4">Select Start Time</label>
                        <div className="form-floating col-m-4 col-6">
                            <select className="form-select" value={startHour} onChange={(e) => setStartHour(e.target.value)}>
                                <option value="">Select hour</option>
                                {hours.map((hour) => (
                                    <option key={hour} value={hour}>{hour}</option>
                                ))}
                            </select>
                        </div>

                        <div className="form-floating col-m-4 col-6">
                            <select className="form-select" value={startMinute} onChange={(e) => setStartMinute(e.target.value)}>
                                <option value="">Select minute</option>
                                {minutes.map((minute) => (
                                    <option key={minute} value={minute}>{minute}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="row col-md-4 col-12">
                        <label className="control-label pt-4 mt-4">Select End Time</label>
                        <div className="form-floating col-m-4 col-6">
                            <select className="form-select" value={endHour} onChange={(e) => setEndHour(e.target.value)}>
                                <option value="">Select hour</option>
                                {hours.map((hour) => (
                                    <option key={hour} value={hour}>{hour}</option>
                                ))}
                            </select>
                        </div>

                        <div className="form-floating col-m-4 col-6">
                            <select className="form-select" value={endMinute} onChange={(e) => setEndMinute(e.target.value)}>
                                <option value="">Select minute</option>
                                {minutes.map((minute) => (
                                    <option key={minute} value={minute}>{minute}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <div className='row justify-content-center'>
                    <button className="btn btn-dark py-4 my-4 col-6" onClick={handleUpdate} disabled={disabled}>{purpose}</button>
                </div>
            </form>
        </div>
    )
}