import React from 'react';
import MealCourseForm from '../../components/Form/meal_course/create';


export default function CreateMealCourse() {
    return (
        <div>
            <MealCourseForm purpose="Create"/>
        </div>
    )
}