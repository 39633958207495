import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Toast } from 'react-bootstrap';
import nutrientService from '../../../api/nutrients_service'
import mealCourseService from '../../../api/meal_course_service';

export default function MealCourseForm({ purpose, id,
    ...props
}) {
    const [showToast, setShowToast] = useState(false);
    const [disabled, setDisabled] = useState(false);
    /*Form Values */
    const [language, setLanguage] = useState("");
    const [name, setName] = useState("");
    const [nameValidator, setNameValidator] = useState(false);
    const [description, setDescription] = useState("");

    useEffect(() => {
        setShowToast(false);
    }, []);

    const getMealCourse = async () => {
        try {
            const getData = await mealCourseService.getMealCourse(id);
            if (getData) {
                setName(getData.names[0].name);
                setLanguage(getData.names[0].language_id);
                setDescription(getData.description);
            }
        }
        catch (error) {
            console.log(error)
        }
    };


    const handleUpdate = async (event) => {
        event.preventDefault();
        const names = { 'language_id': language, 'name': name }

        /* if (validate() === true) {
            console.log("Failed" + nameValidator + macroValidator)
            return
        }*/
        setDisabled(true);

        try {
            const createData = await mealCourseService.updateMealCourse(names, description, id);
            if (createData) {
                setShowToast(true);
                setDisabled(false);
            }
        }
        catch (error) { setDisabled(false); }
    };

    useEffect(() => { getMealCourse() }, []);

    return (
        <div>
            <div className='d-flex justify-content-end'>
                <Toast className="bg-success-subtle text-center" show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                    <Toast.Header className='d-flex justify-content-end'>
                    </Toast.Header>
                    <Toast.Body>Meal Course Updated</Toast.Body>
                </Toast>
            </div>
            <form className='bg-light p-3'>
                <h1 className="h3 mb-3 fw-normal py-4 text-center">Update Meal Course</h1>
                <div className="form-floating required py-4">
                    <input type="text" className={nameValidator ? `form-control is-invalid` : "form-control"} value={name} id="name" onChange={(e) => setName(e.target.value)} />
                    <label className="control-label" htmlFor="floatingInput">Meal Course Name<small className='text-danger'>*</small></label>
                    {nameValidator && (<p className='text-danger'>Name is required.</p>)}
                </div>
                <div className="form-floating py-4">
                    <textarea type="text" className="form-control" value={description} id="description" onChange={(e) => setDescription(e.target.value)} />
                    <label className='control-label' htmlFor="floatingInput">Meal Course Description</label>
                </div>

                <div className='row justify-content-center'>
                    <button className="btn btn-dark py-4 my-4 col-6" onClick={handleUpdate} disabled={disabled}>{purpose}</button>
                </div>
            </form>
        </div>
    )
}