import React, { useState } from 'react'; 

const DynamicPills = ({ items, possibleSubstitutes, selectedIngredients, setSelectedIngredients }) => {
    const [substituteSearchQuery, setSubstituteSearchQuery] = useState('');
    const [visibleSubstitutes, setVisibleSubstitutes] = useState({});

    const handleSubstituteChange = (ingredientId, substituteId, isChecked) => {
        //console.log(isChecked)
        // Update the substitutes for the selected ingredient
        setSelectedIngredients(selectedIngredients?.map(ingredient => {
            if (ingredient?.item_id === ingredientId) {
                //console.log(isChecked)





                const updatedSubstitutes = isChecked
                    ? [...ingredient?.substitutes, substituteId]  // Add if checked
                    : ingredient?.substitutes.filter(id => id !== substituteId);  // Remove if unchecked

                /* console.log('Updated substitutes:', updatedSubstitutes);
 */

                // Return updated ingredient
                const updatedIngredient = { ...ingredient, substitutes: updatedSubstitutes };

                // Log the updated ingredient
                /* console.log('Updated ingredient:', updatedIngredient); */

                return { ...ingredient, substitutes: updatedSubstitutes };
            }

           /*  console.log(selectedIngredients) */
            return ingredient;





        }));
        //console.log(selectedIngredients)
    };



    const handleSubstituteSearchChange = (e) => {
        setSubstituteSearchQuery(e.target.value);
    };

    const getFilteredSubstitutes = (ingredient) => {
        const substitutes = possibleSubstitutes.filter(substitute =>
            substitute.name.toLowerCase().includes(substituteSearchQuery.toLowerCase())
        );
        const selectedSubstitutes = substitutes.filter(substitute =>
            ingredient?.substitutes.includes(substitute._id)
        );
        const unselectedSubstitutes = substitutes.filter(substitute =>
            !ingredient?.substitutes.includes(substitute._id)
        );
        return [...selectedSubstitutes, ...unselectedSubstitutes];
    };

    const toggleSubstitutesVisibility = (ingredientId) => {
        setVisibleSubstitutes({
            ...visibleSubstitutes,
            [ingredientId]: !visibleSubstitutes[ingredientId],
        });
    };

    return (
        <div className="bg-white p-3">
            {/* Display selected ingredients and their substitutes */}
            {selectedIngredients?.map(ingredient => (
                <div key={ingredient?.item_id} className="mt-4">
                    <h5>
                        {ingredient?.name}
                        <button
                            className="btn btn-sm btn-outline-primary ms-3"
                            onClick={(e) => {
                                e.preventDefault();  // Prevent the default form submission
                                toggleSubstitutesVisibility(ingredient?.item_id);
                            }}
                        >
                            {visibleSubstitutes[ingredient?.item_id] ? 'Hide Substitutes' : 'Show Substitutes'}
                        </button>

                    </h5>








                    {visibleSubstitutes[ingredient?.item_id] && (
                        <>
                            <input
                                type="text"
                                className="form-control mb-3"
                                placeholder="Search substitutes"
                                value={substituteSearchQuery}
                                onChange={handleSubstituteSearchChange}
                            />










                            {getFilteredSubstitutes(ingredient)?.map(substitute => (
                                <div key={substitute._id} className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={ingredient?.substitutes.includes(substitute._id)}
                                        onChange={(e) => handleSubstituteChange(ingredient?.item_id, substitute._id, e.target.checked)}
                                    />
                                    <label className="form-check-label">
                                        {substitute.name}
                                    </label>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            ))}
        </div>
    );
};

export default DynamicPills;
