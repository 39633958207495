import React from 'react';
import MealTypeForm from '../../components/Form/meal_type/create';


export default function CreateMealType() {
    return (
        <div>
            <MealTypeForm purpose="Create"/>
        </div>
    )
}