import React from 'react';
import { useParams } from 'react-router-dom';
import MealTypeForm from '../../components/Form/meal_type/update';

export default function ViewMealType() {
    const { id } = useParams();

    return (
        <div>
            <MealTypeForm purpose="Update" id={id} />
        </div>
    )
}