import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import DynamicPills from '../../DynamicPills/DynamicPills';
import mealTypeService from '../../../api/meal_type';
import DishService from '../../../api/dish_service';
import mealCourseService from '../../../api/meal_course_service';
import mealService from '../../../api/meal';

export default function CreateMealForm({ purpose,
    ...props
}) {

    const [showToast, setShowToast] = useState(false);
    const [disabled, setDisabled] = useState(false);
    /*Form Values */
    const [description, setDescription] = useState("");
    const [dishes, setDishes] = useState([]);
    const [dishContainer, setDishesContainer] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [mealType, setMealType] = useState([]);
    const [selectedMealTypes, setSelectedMealTypes] = useState([]);
    const [mealCourse, setMealCourse] = useState("");
    const [mealCoursesList, setMealCoursesList] = useState([]);
    const sets = dishes;

    useEffect(() => {
        setShowToast(false);
    }, []);

    const getDishData = async () => {
        try {
            const getData = await DishService.getDishes();

            if (getData.length > 0) {
                const getDishes = getData.map(data => {
                    data.name = data.names[0].name;
                    return data;
                })

                setDishes(getDishes);
            }
            if (getData) { setDishesContainer(getData) }
        }
        catch (error) {
            //console.log(error)
        }
    };

    const getMealTypeData = async () => {
        try {
            const getData = await mealTypeService.getMealTypes();

            if (getData.length > 0) {
                const getMealType = getData.map(data => {
                    data.name = data.names[0].name;
                    data.score = 0;
                    return data;
                })

                setMealType(getMealType);
            }
        }
        catch (error) {
            //console.log(error)
        }
    };

    const getCoursesData = async () => {
        try {
            const getData = await mealCourseService.getMealCourses();

            if (getData.length > 0) {
                const getCourses = getData.map(data => {
                    data.name = data.names[0].name;
                    return data;
                })
                setMealCoursesList(getCourses);
            }
        }
        catch (error) {
            //console.log(error)
        }
    };

    const handleCreate = async (event) => {
        event.preventDefault();
        setDisabled(true);
        const dishes = selectedOptions.map(data => {
            return { dish_id: data.dish_id, substitutes: data.substitutes } //substitutes: data.substitutes
        });
        const courses = [{ "course_id": mealCourse, "dishes": dishes }]

        const meal_type = selectedMealTypes.reduce((acc, data) => {
            const type = mealType.find(type => type._id === data);
            if (type) {
                const variable = { meal_type_id: data, score: type.score };
                acc.push(variable);
            }
            return acc;
        }, []);

        try {
            const createData = await mealService.createMeal(description, courses, meal_type);
            
            if (createData) {
                setShowToast(true);
                setDisabled(false);
            }
        }
        catch (error) { setDisabled(false); }
    };

    const handleMealTypeChecked = (id, event) => {
        if (!selectedMealTypes.includes(id)) {
            setSelectedMealTypes([...selectedMealTypes, id]);
        }
        else {
            setSelectedMealTypes(selectedMealTypes.filter(data => data !== id))
        }
    }

    /*************Handling and creating Table fields of selected ingredients */
    const handleSelectDish = (event) => {
        var id = event.target.id;
        var itsPresent = false;
        var dish = { name: event.target.name, dish_id: id, substitutes: [], item_id: id }
        let selectedLabels = [...selectedOptions];

        if (selectedLabels.length > 0) {
            itsPresent = selectedLabels.some(item => item.dish_id.includes(dish?.dish_id))
        }
        if (itsPresent) {
            selectedLabels = selectedLabels.filter(item => item.dish_id !== dish?.dish_id);
            setSelectedOptions(selectedLabels);
        }
        else {
            selectedLabels = [...selectedOptions, dish]
            setSelectedOptions(selectedLabels);
        }
    };

    /*******Search algorithm */
    const search = event => {
        event.preventDefault();
        const input = event?.target?.value?.toLowerCase();
        let filteredList = sets;

        filteredList = sets?.filter(item => {
            let result = false;
            if (item?.name?.toLowerCase()?.includes(input) && result === false) {
                result = true;
            }
            return result;
        });

        setDishesContainer(filteredList);
    };

    const list = dishContainer?.map(set => {
        return (
            <div className='mb-3 col-6' key={set._id}>
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" id={set._id} name={set.name} onClick={handleSelectDish} />
                    <label className="form-check-label w-100" htmlFor={set.name} >
                        {set.name}
                    </label>
                </div>
            </div>)
    })

    //console.log(mealType)
    const mealTypeList = mealType.map(set => {
        return (
            <tr key={set.item_id} className="g-3">
                <td className='col'><input className="form-check-input" type="checkbox" checked={selectedMealTypes.includes(set._id) || ''} id="flexCheckDefault" onChange={(e) => handleMealTypeChecked(set._id, e)} /></td>
                <th className='col' scope="row">{set.name}</th>
                <td className='col'> <div className="form-floating">
                    <div className="form-floating">
                        <select className="form-select" onChange={(e) => { set.score = e.target.value }}>
                            <option key={`a${set._id}`} value="0">Neutral</option>
                            <option key={`b${set._id}`} value="1">Good</option>
                            <option key={`c${set._id}`} value="2">Great</option>
                            <option key={`d${set._id}`} value="-1">Bad</option>
                        </select>
                    </div>
                </div></td>
            </tr>
        );
    });

    const courseList = mealCoursesList?.map(data => {
        return <option key={data["_id"]} value={data["_id"]}>{data.names[0].name}</option>
    })

    useEffect(() => { getMealTypeData() }, []);
    useEffect(() => { getDishData() }, []);
    useEffect(() => { getCoursesData() }, []);

    return (
        <div>
            <div className='d-flex justify-content-end'>
                <Toast className="bg-success-subtle text-center" show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                    <Toast.Header className='d-flex justify-content-end'>
                    </Toast.Header>
                    <Toast.Body>Meal Created</Toast.Body>
                </Toast>
            </div>

            <form className='bg-light p-3'>
                <h1 className="h3 mb-3 fw-normal py-4 text-center">Create Meal</h1>
                <div className="form-floating py-4">
                    <textarea type="text" className="form-control" id="description" onChange={(e) => setDescription(e.target.value)} />
                    <label className='control-label' htmlFor="floatingInput">Meal Description</label>
                </div>

                {/***THis is the main aprt worked on */}
                <div className="g-3  py-4">
                    <h4 className="">Meal Type</h4>
                    <table className="table table-striped ">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Meal Type</th>
                                <th scope="col">Appropriate Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mealTypeList}
                        </tbody>
                    </table>
                </div>

                <div className=" mt-5 ">
                    <h3 className="py-4">Manage Meal Courses</h3>
                    <div className="list-inline row">
                        <div className="form-floating col-6">
                            <select className="form-select" value={mealCourse} onChange={(e) => setMealCourse(e.target.value)}>
                                <option key="select">Select Course</option>
                                {courseList}
                            </select>
                        </div>
                        <div className="dropdown list-inline-item col-6">
                            <button type="button" className="btn btn-dark btn-lg dropdown-toggle w-100" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                Add Dishes
                            </button>
                            <div className="dropdown-menu p-4 w-50">
                                <div className="w-100 fluid" role="search">
                                    <input className="form-control" type="search" placeholder="Search" aria-label="Search" onChange={search} />
                                </div>
                                <div className="row">
                                    {list}
                                </div>
                            </div>
                        </div>
                    </div>
                    <DynamicPills items={selectedOptions} possibleSubstitutes={dishes} selectedIngredients={selectedOptions} setSelectedIngredients={setSelectedOptions} />
                </div>

                <div className='row justify-content-center'>
                    <button className="btn btn-dark py-4 my-4 col-6" onClick={handleCreate} disabled={disabled}>{purpose}</button>
                </div>
            </form>
        </div>
    )
}